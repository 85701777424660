import { genericService } from "./genericService";
import { akeronService } from "./akeronService";
const section = "HeaderContainerTrip";

function getAllWarehouse() {
  return genericService.get("Warehouse/ListWarehouseAll");
}

function filter(param, from = "0", limit = "10") {
  return akeronService
    .make_post_request("HeaderContainerTrip/ListCustomerHeaderContainerTrip", {
      FechaDesde: param.dateInicio_filter,
      FechaHasta: param.dateFin_filter,
      jtStartIndex: from,
      jtPageSize: limit,
      IdChofer: param.chofer_filter != null ? param.chofer_filter.id : 0,
      NumTrip: param.ruta_filter != "" ? parseInt(param.ruta_filter) : 0,
      NumContenedor: param.numContainer_filter,
      ExternalCode: param.externalCode_filter,
      idVehiculo: param.vehiculo_filter != null ? param.vehiculo_filter.id : 0,
      IdClient: param.cliente_filter != null ? param.cliente_filter.id : 0,
      Color: param.color_filter != null ? param.color_filter.id : "",
      typeTrip: param.operador_filter != null ? param.operador_filter.id : 0,
    })
    .then((response) => {
      let result = {
        quantity: response.quantity,
        list: response["containerTrips"],
      };
      return result;
    });
}

function getRuta(id) {
  return genericService.get("HeaderContainerTrip/FindHeaderContainerTrip/" + id);
}

function updateStage(param) {
  var params;
  if (param.images.length > 0) {
    params = {
      Id: param.id,
      Date: param.date, //"2022-04-10T14:24:00-03:00",
      Image: param.images,
    };
  } else {
    params = {
      Id: param.id,
      Date: param.date,
    };
  }

  return akeronService.make_put_request("HeaderContainerTrip/UpdateStageContainerTrip", params).then((response) => {
    return response;
  });
}

function resetStage(id) {
  var params = {
    Id: id,
  };

  return akeronService.make_put_request("HeaderContainerTrip/OpenStageContainerTrip", params).then((response) => {
    return response;
  });
}

function create(item) {
  const param = {
    DateStringPickup: item.datePickup + " " + item.time,
    Client: item.client.id,
    ExternalCode: item.externalCode,
    NumContainer: item.numContainer,
    PesoContainer: item.pesoContainer,
    IdOriginPort: item.originPort.id,
    IdWarehouse: item.warehouse.id,
    IdDriverPickup: item.driverPickup.id,
    IdVehiclePickup: item.vehiclePickup.id,
    IdTypeChargePickup: item.typeChargePickup.id,
    IdTypeServiceCharge: item.typeServiceCharge.id,
    IdZonePickup: item.zonePickup.id,
    IdTypeTripPickup: item.typeTripPickup.id,
    DateStringDelivery: item.dateDelivery + " " + item.time_delivery,
    IdDestinationPort: item.destinationPort.id,
    IdDriverDelivery: item.driverDelivery.id,
    IdVehicleDelivery: item.vehicleDelivery.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.companyCustody != null ? 1 : item.quantityModules,
    Aditionals: item.aditionals,
    isPurchaseNew: true,
  };

  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    DateStringPickup: item.datePickup + " " + item.time,
    Client: item.client.id,
    ExternalCode: item.externalCode,
    NumContainer: item.numContainer,
    PesoContainer: item.pesoContainer,
    IdOriginPort: item.originPort.id,
    IdWarehouse: item.warehouse.id,
    IdDriverPickup: item.driverPickup.id,
    IdVehiclePickup: item.vehiclePickup.id,
    IdTypeChargePickup: item.typeChargePickup.id,
    IdTypeServiceCharge: item.typeServiceCharge.id,
    IdZonePickup: item.zonePickup.id,
    IdTypeTripPickup: item.typeTripPickup.id,
    DateStringDelivery: item.dateDelivery + " " + item.time_delivery,
    IdDestinationPort: item.destinationPort.id,
    IdDriverDelivery: item.driverDelivery.id,
    IdVehicleDelivery: item.vehicleDelivery.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.companyCustody != null ? 1 : item.quantityModules,
    Aditionals: item.aditionals,
    Color: item.color,
    isPurchaseNew: true,
  };

  return genericService.update(section, param);
}

function remove(id) {
  return genericService.remove(section, id);
}

function addImages(param) {
  return akeronService
    .make_post_request("HeaderContainerTrip/AddImageHeaderTrip", {
      IdTrip: param.ruta,
      Image: param.images,
    })
    .then((response) => {
      return response;
    });
}
/*
function getStates() {
  return [
    "128, 128, 128", //gris
    "255, 241, 89", //amarillo
    "95, 186, 12", //verde
  ];
}
*/

const GRIS = "128, 128, 128";
const AZUL = "115, 144, 252";
const AMARILLO = "255, 241, 89";
const VERDE = "95, 186, 125";
const ROJO = "230, 25, 25";
const VIOLETA = "95, 50, 162";
const NARANJA = "255, 128, 0";
const NEGRO = "0, 0, 0";

function getStates() {
  return [
    { id: GRIS, name: "Abierto" }, //gris
    { id: AZUL, name: "Cerrado" }, //azul
    { id: AMARILLO, name: "Finalizado" }, //amarillo
    { id: VERDE, name: "Tránsito" }, //verde
    { id: ROJO, name: "Tarde" }, //rojo
    { id: VIOLETA, name: "En Horario" }, //violeta
    { id: NARANJA, name: "Falso Flete" }, //naranja
    { id: NEGRO, name: "Anulado" }, //negro
  ];
}

function getStateName(id) {
  switch (id) {
    case GRIS:
      return "Abierto";
    case AZUL:
      return "Cerrado";
    case AMARILLO:
      return "Finalizado";
    case VERDE:
      return "Tránsito";
    case ROJO:
      return "Tarde";
    case VIOLETA:
      return "En Horario";
    case NARANJA:
      return "Falso Flete";
    case NEGRO:
      return "Anulado";
  }
}

export const rutasContainerService = {
  addImages,
  getStates,
  getStateName,
  getRuta,
  filter,
  remove,
  create,
  update,
  updateStage,
  resetStage,
  getAllWarehouse,
};

<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('close')" v-on:input="$emit('close')" max-width="500px">
      <v-card>
        <v-form v-on:submit.prevent="addItem">
          <v-card-title>
            <span class="headline">Adicional</span>
          </v-card-title>

          <v-card-text>
            <v-combobox
              v-model="item.adicional"
              data-vv-as="Adicional"
              name="Adicional"
              item-text="nombre"
              item-value="id"
              :items="adicionales"
              label="Adicional"
              autocomplete="off"
              v-validate="'required'"
              :disabled="edit"
            ></v-combobox>
            <span class="red--text">{{ errors.first("Adicional") }}</span>

            <v-text-field data-vv-as="Importe" :label="$t('Importe')" name="Importe" v-model="item.cost" v-validate="'required|decimal'"></v-text-field>
            <span class="red--text">{{ errors.first("Importe") }}</span>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('close')">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit">{{ edit ? "Editar" : "Agregar" }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { adicionalesService } from "@/libs/ws/adicionalesService";
export default {
  props: {
    dialog: Boolean,
    itemAdicional: Object,
    close: Function,
    action: Function,
  },
  data() {
    return {
      adicionales: [],
      edit: false,
      item: {
        adicional: null,
        cost: 0,
      },
      itemDefault: {
        adicional: null,
        cost: 0,
      },
    };
  },
  watch: {
    itemAdicional() {
      if (this.itemAdicional != null) {
        this.item = this.itemAdicional;
        this.edit = true;
      } else {
        this.edit = false;
      }
    },
    dialog() {
      this.errors.clear();
      if (!this.dialog) {
        this.item = Object.assign({}, this.itemDefault);
      }
    },
  },
  mounted() {
    this.loadAdicionales();
  },
  methods: {
    addItem() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("action", this.item);
        }
      });
    },
    loadAdicionales() {
      adicionalesService
        .getAll()
        .then((response) => {
          this.adicionales = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
  },
};
</script>

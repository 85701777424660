import { genericService } from "./genericService";
import { akeronService } from "./akeronService";
const section = "HeaderTrip";

const FILTER_DATE = "FECHA";
const FILTER_DRIVER = "CHOFER";
const FILTER_VEHICLE = "CAMION";

function filter(param, from = 0, limit = 10) {
  console.log(param);
  return akeronService
    .make_post_request("HeaderTrip/ListCustomerHeaderTripNew", {
      FechaDesde: param.dateInicio_filter,
      FechaHasta: param.dateFin_filter,
      jtStartIndex: from,
      jtPageSize: limit,
      IdChofer: param.chofer_filter != null ? param.chofer_filter.id : 0,
      NumTrip: param.ruta_filter != "" ? parseInt(param.ruta_filter) : 0,
      NumRemito: param.remito_filter,
      idVehiculo: param.vehiculo_filter != null ? param.vehiculo_filter.id : 0,
      SoloPendientes: param.onlyPendientes,
      IdClient: param.cliente_filter != null ? param.cliente_filter.id : 0,
      Color: param.color_filter != null ? param.color_filter : "",
      ExternalCode: param.externalCode_filter,
      typeTrip: param.operador_filter != null ? param.operador_filter.id : 0,
    })
    .then((response) => {
      let result = {
        quantity: response.quantity,
        list: response["headerTrips"],
      };
      return result;
    });
}

function remove(id) {
  return genericService.remove(section, id);
}

function getAll() {
  return genericService.get("HeaderTrip/ListHeaderTrip");
}

function getRuta(id) {
  return genericService.get("HeaderTrip/findHeaderTripNew/" + id);
}

function create(item) {
  const param = {
    DateString: item.date + " " + item.time,
    IdOrigen: item.origen.id,
    IdDriver: item.driver.id,
    IdVehicle: item.vehicle.id,
    IdTypeCharge: item.typeCharge.id,
    IdTypeServiceCharge: item.typeServiceCharge.id,
    IdDestination: item.destination.id,
    IdZone: item.zone.id,
    IdTypeTrip: item.typeTrip.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    Aditionals: item.aditionals,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.companyCustody != null ? 1 : item.quantityModules,
    ExternalCode: item.externalCode,
  };
  console.log(param);
  return genericService.create(section, param);
}

function createNew(item) {
  const param = {
    DateString: item.date + " " + item.time,
    IdOrigen: item.origen.id,
    IdDriver: item.driver.id,
    IdVehicle: item.vehicle.id,
    IdTypeCharge: item.typeCharge.id,
    IdTypeServiceCharge: item.typeServiceCharge.id,
    IdDestination: item.destination.id,
    IdZone: item.zone.id,
    IdTypeTrip: item.typeTrip.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    Aditionals: item.aditionals,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.companyCustody != null ? 1 : item.quantityModules,
    ExternalCode: item.externalCode,
    isPurchaseNew: true,
  };
  return akeronService.make_post_request("HeaderTrip/CreateHeaderTripAditionals", param).then((response) => {
    return response;
  });
}

function updateNew(item) {
  const param = {
    numTrip: item.numTrip,
    id: item.id,
    DateString: item.date + " " + item.time,
    //HoraString: item.time,
    IdOrigen: item.origen.id,
    IdDriver: item.driver.id,
    IdVehicle: item.vehicle.id,
    IdTypeCharge: item.typeCharge.id,
    IdDestination: item.destination.id,
    IdZone: item.zone.id,
    IdTypeTrip: item.typeTrip.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    Aditionals: item.aditionals,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.companyCustody != null ? 1 : item.quantityModules,
    ExternalCode: item.externalCode,
    isPurchaseNew: true,
  };
  return akeronService.make_put_request("HeaderTrip/UpdateHeaderTripNewAditionals", param).then((response) => {
    return response;
  });
}

function update(item) {
  const param = {
    numTrip: item.numTrip,
    id: item.id,
    DateString: item.date,
    IdOrigen: item.origen.id,
    IdDriver: item.driver.id,
    IdVehicle: item.vehicle.id,
    IdTypeCharge: item.typeCharge.id,
    IdDestination: item.destination.id,
    IdZone: item.zone.id,
    IdTypeTrip: item.typeTrip.id,
    BasicCost: item.basicCost,
    TotalCost: item.totalCost,
    Observation1: item.observation1,
    Observation2: item.observation2,
    QuantityAssistant: item.quantityAssistant,
    QuantityDelay: item.quantityDelay,
    QuantityStays: item.quantityStays,
    QuantityTurns: item.quantityTurns,
    IdCompanyCustody: item.companyCustody != null ? item.companyCustody.id : 0,
    QuantityModules: item.companyCustody != null ? 1 : item.quantityModules,
    ExternalCode: item.externalCode,
  };
  return genericService.update(section, param);
}

function addImages(param) {
  console.log(param);
  return akeronService
    .make_post_request("HeaderTrip/AddImageHeaderTrip", {
      IdTrip: param.ruta,
      Image: param.images,
    })
    .then((response) => {
      return response;
    });
}

function getRutaById(id) {
  return genericService.get("HeaderTrip/FindHeaderTripByNumTrip/" + id);
}

function OrderDelivered(params) {
  return akeronService.make_post_request("/HeaderTrip/OrderDelivered", params).then((response) => {
    return response;
  });
}

function changeState(params) {
  return akeronService.make_post_request("/HeaderTrip/UpdateColor", params).then((response) => {
    return response;
  });
}

const GRIS = "128, 128, 128";
const AZUL = "115, 144, 252";
const AMARILLO = "255, 241, 89";
const VERDE = "95, 186, 12";
const ROJO = "230, 25, 25";
const VIOLETA = "95, 50, 162";
const NARANJA = "255, 128, 0";
const NEGRO = "0, 0, 0";

function getStates() {
  return [
    { id: GRIS, name: "Abierto" }, //gris
    { id: AZUL, name: "Cerrado" }, //azul
    { id: AMARILLO, name: "Finalizado" }, //amarillo
    { id: VERDE, name: "Tránsito" }, //verde
    { id: ROJO, name: "Tarde" }, //rojo
    { id: VIOLETA, name: "En Horario" }, //violeta
    { id: NARANJA, name: "Falso Flete" }, //naranja
    { id: NEGRO, name: "Anulado" }, //negro
  ];
}

function getStateName(id) {
  switch (id) {
    case GRIS:
      return "Abierto";
    case AZUL:
      return "Cerrado";
    case AMARILLO:
      return "Finalizado";
    case VERDE:
      return "Tránsito";
    case ROJO:
      return "Tarde";
    case VIOLETA:
      return "En Horario";
    case NARANJA:
      return "Falso Flete";
    case NEGRO:
      return "Anulado";
  }
}

export const rutasService = {
  FILTER_DATE,
  FILTER_DRIVER,
  FILTER_VEHICLE,
  changeState,
  getStates,
  getStateName,
  addImages,
  getRuta,
  getAll,
  filter,
  remove,
  create,
  update,
  getRutaById,
  createNew,
  updateNew,
  OrderDelivered,
};

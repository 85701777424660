import { genericService } from "./genericService";

const section = "TypeServiceCharge";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "types", name, from, limit, active);
}
function getAll() {
  return genericService.get("TypeServiceCharge/ListAllTypeServiceCharge");
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    typeServiceCharge: item.typeCharge,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    typeServiceCharge: item.typeCharge,
    isActive: item.isActive,
  };
  return genericService.update(section, param);
}

export const typeCharge = {
  getAll,
  filter,
  remove,
  create,
  update,
};

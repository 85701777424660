<template>
  <div class="login">
    <div class="row bg justify-content-md-center align-items-center">
      <div class="col-md-4">
        <v-card :dark="true" :flat="true" class="rounded-0">
          <v-card-title class="justify-content-center">
            <v-img
              :src="require('@/assets/images/logo.png')"
              contain
              max-width="200"
              class="pt-10"
            />
          </v-card-title>
          <v-card-text class="padding">
            <v-form>
              <p v-if="login_error != ''" class="red--text">
                Los datos ingresados son incorrectos. Vuelve a intentarlo
              </p>
              <v-text-field
                label="Username"
                v-model="username"
                prepend-icon="mdi-account"
                v-on:keyup.enter="auth"
              />
              <v-text-field
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show = !show"
                :type="show ? 'text' : 'password'"
                label="Password"
                v-model="password"
                prepend-icon="mdi-lock"
                v-on:keyup.enter="auth"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="padding">
            <v-btn
              class="w-100 mb-8 btn rounded-0"
              v-on:click="auth"
              :loading="loading"
              >Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { authService } from "@/libs/ws/authService";
import Constant from "@/constants/";
export default {
  name: "App",

  components: {},

  data: () => ({
    login_error: "",
    username: "",
    password: "",
    show: false,
    loading: false,
  }),

  methods: {
    auth: function(event) {
      this.loading = true;
      authService
        .login(this.username, this.password)
        .then((response) => {
          if (
            Object.prototype.hasOwnProperty.call(response, "status") &&
            response.status == 401
          ) {
            this.login_error = response.msg;
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "token")) {
              localStorage.setItem("token", response.token);
              localStorage.setItem("expires", response.expires);
              localStorage.setItem("username", response.username);

              //console.log(response.rol);
              const encryptedTextExternal = this.CryptoJS.AES.encrypt(
                JSON.stringify(response.isExternal),
                Constant.SECRET_PHRASE
              ).toString();

              localStorage.setItem("external", encryptedTextExternal);

              if (response.client != null) {
                localStorage.setItem(
                  "cliente",
                  this.CryptoJS.AES.encrypt(
                    JSON.stringify(
                      response.client.id + "-" + response.client.nameClient
                    ),
                    Constant.SECRET_PHRASE
                  ).toString()
                );
              }

              if (response.companyName != "") {
                localStorage.setItem(
                  "company",
                  this.CryptoJS.AES.encrypt(
                    JSON.stringify(response.companyName),
                    Constant.SECRET_PHRASE
                  ).toString()
                );
              }

              const encryptedText = this.CryptoJS.AES.encrypt(
                JSON.stringify(response.rol),
                Constant.SECRET_PHRASE
              ).toString();
              localStorage.setItem("rol", encryptedText);
              //this.$router.push("/");
              this.$router.go("/");
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          console.log("Auth error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Title',{attrs:{"pages":[{ icon: 'chart-bar', page: _vm.page_title.toUpperCase() }]}}),(_vm.list_access)?_c('v-container',{attrs:{"fluid":""}},[_c('v-form',[_c('div',{staticClass:"row filter justify-content-md-center mt-10"},[_c('div',{staticClass:"col-md-5 "},[_c('div',{staticClass:"row",staticStyle:{"justify-content":"center","display":"flex","text-align":"center"}},[_c('div',{staticClass:"col-md-12",staticStyle:{"justify-content":"center","display":"flex","text-align":"center"}},[_c('v-combobox',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"label":"Tipo de informe","name":"tipodeinforme","items":_vm.tipoInformes},on:{"change":function($event){return _vm.limpiarComboBox()}},model:{value:(_vm.rtaTipo),callback:function ($$v) {_vm.rtaTipo=$$v},expression:"rtaTipo"}}),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.errors.first("tipodeinforme")))])],1)]),(_vm.rtaTipo != 'Total' && _vm.rtaTipo != '')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-combobox',{attrs:{"search-input":_vm.searchInput,"label":_vm.rtaTipo != 'Facturacion' && _vm.rtaTipo != 'Diagramador' ? _vm.rtaTipo : 'Cliente',"items":_vm.rtaTipo == 'Chofer'
                    ? _vm.drivers
                    : '' || _vm.rtaTipo == 'Transporte'
                    ? _vm.transporte
                    : '' || _vm.rtaTipo == 'Cliente'
                    ? _vm.clients
                    : '' || _vm.rtaTipo == 'Facturacion'
                    ? _vm.clients
                    : '' || _vm.rtaTipo == 'Diagramador'
                    ? _vm.clients
                    : '' || _vm.rtaTipo == 'Subcliente'
                    ? _vm.subclients
                    : '' || _vm.rtaTipo == 'Puerto origen' || 'Puerto destino'
                    ? _vm.origines
                    : '',"item-text":_vm.rtaTipo == 'Chofer'
                    ? 'nameDriver'
                    : '' || _vm.rtaTipo == 'Transporte'
                    ? 'nameTransport'
                    : '' || _vm.rtaTipo == 'Cliente'
                    ? 'nameClient'
                    : '' || _vm.rtaTipo == 'Facturacion'
                    ? 'nameClient'
                    : '' || _vm.rtaTipo == 'Diagramador'
                    ? 'nameClient'
                    : '' || _vm.rtaTipo == 'Puerto origen' || 'Puerto destino'
                    ? 'dirOrigen'
                    : '',"loading":_vm.loading_subclientes,"item-value":"id"},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"keyup":function($event){return _vm.loadSubclients()}},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Desde","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateInicio_filter),callback:function ($$v) {_vm.dateInicio_filter=$$v},expression:"dateInicio_filter"}},'v-text-field',attrs,false),on))]}}],null,false,3769418341)},[_c('v-date-picker',{attrs:{"min":"1950-01-01"},model:{value:(_vm.dateInicio_filter),callback:function ($$v) {_vm.dateInicio_filter=$$v},expression:"dateInicio_filter"}})],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Hasta","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateFin_filter),callback:function ($$v) {_vm.dateFin_filter=$$v},expression:"dateFin_filter"}},'v-text-field',attrs,false),on))]}}],null,false,4202823059)},[_c('v-date-picker',{attrs:{"min":"1950-01-01"},model:{value:(_vm.dateFin_filter),callback:function ($$v) {_vm.dateFin_filter=$$v},expression:"dateFin_filter"}})],1)],1)]),_c('div',{staticClass:"row mb-2",staticStyle:{"justify-content":"center","display":"flex","text-align":"center"}},[_c('download-excel',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"refDownload",attrs:{"data":_vm.json_data,"worksheet":"My Worksheet","name":'reporte_contenedores_' + _vm.rtaTipo.toLowerCase() + '.xls'}}),_c('v-btn',{attrs:{"loading":_vm.loading_reporte},on:{"click":function($event){return _vm.validateCampos()}}},[_vm._v(" Descargar ")])],1)])])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
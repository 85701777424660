<script>
// definir un objeto mixin
export default {
  data() {
    return {
      show: true,
      dialog: false,
      rowsPerPageItems: [10, 20, 30, 40],
      pagination: {
        page: 1,
        itemsPerPage: 5,
      },
      dialog_delete: false,
      search: "",
      rowNumber: 0,
      deleteIndex: 0,
      deleteId: 0,
      editedIndex: -1,
      viewdIndex: -1,
      loading: false,
      list: [],
      cant_pages: 0,
      current_page: 1,
      name_filter: "",
      active_filter: 1,
    };
  },
  created: function() {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  computed: {
    formBtn() {
      if (this.editedIndex === -1 && this.viewdIndex === -1) {
        return this.$t("Crear");
      } else if (this.viewdIndex === -1) {
        return "Editar";
      }
      return "";
    },
  },
  mounted() {},
  methods: {
    updateDialog(value) {
      if (!value) {
        this.close();
      }
      this.dialog = value;
    },
    updateDialogDelete(value) {
      this.dialog_delete = value;
    },
    viewItem(item) {
      this.viewdIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);
      this.dialog = true;
    },
    editItem(item) {
      this.errors.clear();
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.deleteIndex = this.list.indexOf(item);
      this.deleteId = item.id;
      this.dialog_delete = true;
    },
    close() {
      this.mainItem = Object.assign({}, this.defaultItem);
      this.dialog = false;
      this.editedIndex = -1;
      this.viewdIndex = -1;
    },
    back() {
      this.$router.back();
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    loadList(service, name = this.name_filter, startIndex = 0, active = this.active_filter) {
      this.loading = true;
      //Controls
      if (active) {
        active = 1;
      } else {
        active = 0;
      }
      if (name.length == 0) {
        name = " ";
      } else {
        name = name.trim();
      }
      //
      service
        .filter(name, startIndex, this.$store.state.config.items_per_page, active)
        .then((response) => {
          if (startIndex == 0) {
            this.current_page = 1;
          }

          this.list = response.list;
          console.log("list", this.list);
          this.cant_pages = Math.ceil(response.quantity / this.$store.state.config.items_per_page);

          if (this.selected != undefined) {
            this.selected = [];
          }

          this.loading = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    loadPage(service) {
      this.loadList(service, this.name_filter, (this.current_page - 1) * this.$store.state.config.items_per_page, this.active_filter);
    },
    delete(service) {
      service
        .remove(this.deleteId)
        .then((response) => {
          if (response.status == 200) {
            this.$toast.success(response.title, "OK");
            this.loadListCustom(this.service, (this.current_page - 1) * this.$store.state.config.items_per_page);
          } else {
            this.$toast.error(response.title, "Error");
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        });
    },
  },
};
</script>

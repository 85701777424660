<template>
  <div>
    <Title :pages="[{ icon: 'truck', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadListCustom(service, (current_page - 1) * $store.state.config.items_per_page)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <!--Filter-->
      <div class="row mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-2">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="ruta_filter.length > 0 || numContainer_filter.length > 0 || externalCode_filter.length > 0"
                      v-model="dateInicio_filter"
                      :label="$t('Fecha_desde')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateInicio_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>
              <div class="col-md-2">
                <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :disabled="ruta_filter.length > 0 || numContainer_filter.length > 0 || externalCode_filter.length > 0"
                      v-model="dateFin_filter"
                      :label="$t('Fecha_hasta')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dateFin_filter" min="1950-01-01"></v-date-picker>
                </v-menu>
              </div>

              <div class="col">
                <v-text-field data-vv-as="ruta" :label="$t('Ruta')" name="zone" v-model="ruta_filter"></v-text-field>
              </div>
              <div class="col">
                <v-text-field data-vv-as="remito" label="Nº contenedor" name="numContainer" v-model="numContainer_filter"></v-text-field>
              </div>
              <div class="col">
                <v-text-field data-vv-as="id externo" label="Id externo" name="externalCode" v-model="externalCode_filter"></v-text-field>
              </div>
            </div>
            <!-- fin row1 -->

            <div class="row">
              <div class="col">
                <v-combobox v-model="chofer_filter" name="driver" item-text="nameDriver" item-value="id" :items="drivers" :label="$t('Chofer')" autocomplete="off" :clearable="true"></v-combobox>
              </div>
              <div class="col">
                <v-combobox v-model="vehiculo_filter" name="vehicles" item-text="patent" item-value="id" :items="vehiculos" :label="$t('Vehiculo')" autocomplete="off" :clearable="true"></v-combobox>
              </div>
              <div class="col">
                <v-combobox v-model="cliente_filter" item-text="nameClient" item-value="id" :items="clients" :label="$t('Cliente')" autocomplete="off" :clearable="true"></v-combobox>
              </div>

              <div class="col">
                <v-combobox v-model="color_filter" :items="items_color" label="Estado" clearable>
                  <template v-slot:selection="{ item }">
                    <v-progress-linear :value="100" :color="'rgba(' + item.id + ')'" style="height: 20px !important;">
                      <span class="text-white">{{ item.name }}</span>
                    </v-progress-linear>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-progress-linear :value="100" :color="'rgba(' + item.id + ')'" style="height: 20px !important;">
                      <span class="text-white">{{ item.name }}</span>
                    </v-progress-linear>
                  </template>
                </v-combobox>
              </div>

              <div class="col">
                <v-combobox v-model="operador_filter" item-text="typeTrip" item-value="id" :items="operadores" :label="$t('Tipo_contratacion')" autocomplete="off" :clearable="true"></v-combobox>
              </div>

              <div class="col d-flex align-items-right justify-content-end pt-8">
                <v-btn color="primary" dark @click="loadListCustom(service)">{{ $t("Buscar") }}</v-btn>
              </div>

              <!-- fin row2 -->
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter -->

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" :label="$t('Buscar')" single-line hide-details></v-text-field>
        </v-card-title>

        <v-data-table :headers="headers" :items="list" :single-expand="false" :loading="loading" :expanded.sync="expanded" show-expand class="elevation-1" hide-default-footer disable-pagination>
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td>
                <font-awesome-icon v-if="!isExpanded" icon="chevron-down" @click="expand(!isExpanded)" class="cursor" />
                <font-awesome-icon v-else icon="chevron-up" @click="expand(!isExpanded)" class="cursor" />
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.numTrip }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ new Date(item.datePickup).toLocaleDateString() + " " + item.datePickup.substr(11, 8) }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.typeChargePickup != null ? item.typeChargePickup.typeCharge : "" }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.client.nameClient }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.originPort.dirOrigen }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.vehiclePickup.type.type }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.vehiclePickup.patent }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                {{ item.driverPickup.nameDriver }}
              </td>
              <td @click="expand(!isExpanded)" class="cursor">
                <v-progress-linear :value="100" :color="'rgba(' + item.color + ')'" style="height: 20px !important;">
                  <span class="text-white">{{ service.getStateName(item.color) }}</span>
                </v-progress-linear>
              </td>

              <td class="text-center  px-0" style="width:150px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="openAddImages(item)">
                      image
                    </v-icon>
                  </template>
                  <span>Imagenes</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="purple" class="mr-2" @click="generarHojaDeRuta(item)">
                      mdi-arrow-down-bold-box-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("Descargar_hoja_ruta") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewItem(item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>{{ $t("Ver") }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="update_access">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(item)">
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="delete_access">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" class="mr-2" @click="deleteItem(item)">
                      delete
                    </v-icon>
                  </template>
                  <span>{{ $t("Anular_viaje") }}</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-data-table
                :headers="headersStages"
                :items="item.stages"
                :search="search"
                :loading-text="$t('cargando_espere')"
                hide-default-footer
                disable-pagination
                class="background-color: grey  lighten-3"
              >
                <template v-slot:item="props">
                  <tr>
                    <td class="text-center">{{ props.item.order }}</td>
                    <td>{{ props.item.description }}</td>
                    <td>{{ props.item.delivery != null ? "Finalizada" : "En espera" }}</td>
                    <td>{{ toDateString(props.item.delivery) }}</td>
                    <td class="justify-content-left layout px-0 pl-5">
                      <v-tooltip v-if="props.item.image.length > 0 && props.item.delivery != null" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="blue" class="mr-2" @click="viewImage(props.item)">
                            remove_red_eye
                          </v-icon>
                        </template>
                        <span>{{ $t("Ver") + " imagenes" }}</span>
                      </v-tooltip>

                      <v-tooltip v-if="props.item.delivery == null && update_access" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="green" class="mr-2" @click="updateStageState(props.item)">
                            check
                          </v-icon>
                        </template>
                        <span>Finalizar tarea</span>
                      </v-tooltip>

                      <v-tooltip v-if="props.item.delivery != null && update_access" bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="red" class="mr-2" @click="resetStage(props.item)">
                            indeterminate_check_box
                          </v-icon>
                        </template>
                        <span>Resetear tarea</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
                <template v-slot:no-results>
                  <v-alert :value="true" color="error" icon="warning">
                    {{ $t("Sin_resultados") + search }}
                  </v-alert>
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
      </v-card>
      <div v-if="cant_pages > 1" class="text-center">
        <v-pagination
          v-if="list_access && dateInicio_filter != dateFin_filter"
          class="mt-5 mb-5"
          v-model="current_page"
          :length="cant_pages"
          total-visible="10"
          circle
          @next="loadPageCustom(service)"
          @previous="loadPageCustom(service)"
          @input="loadPageCustom(service)"
        ></v-pagination>
      </div>

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />

      <v-dialog v-if="updateItemStage != null" v-model="dialog_confirm_resetStage" max-width="500">
        <v-card>
          <v-card-title class="headline">Resetear Tarea</v-card-title>

          <v-card-text>
            <p class="mt-5 mb-0 fs-16">Estas por resetear la tarea '{{ updateItemStage.description }}'. ¿Estas seguro?</p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="dialog_confirm_resetStage = false">
              Cancelar
            </v-btn>

            <v-btn color="green darken-1" :loading="loadingUpdate" text @click="confirmResetStage()">
              Editar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-if="updateItemStage != null" v-model="dialog_confirm_updateStage" max-width="500">
        <v-card>
          <v-card-title class="headline">Finalizar Tarea</v-card-title>

          <v-card-text>
            <p class="mt-5 mb-0 fs-16">Estas por finalizar la tarea '{{ updateItemStage.description }}'.</p>

            <v-menu ref="menu" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field class="mt-4" v-model="dateEntregada" label="Fecha" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateEntregada" min="1950-01-01"></v-date-picker>
            </v-menu>

            <v-menu
              ref="menu"
              v-model="menu_time"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="timeEntrega"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeEntrega"
                  label="Hora de entrega"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-validate="'required'"
                  v-bind="attrs"
                  name="time"
                  v-on="on"
                ></v-text-field>
                <span class="red--text">{{ errors.first("time") }}</span>
              </template>
              <v-time-picker v-if="menu_time" v-model="timeEntrega" full-width @click:minute="$refs.menu.save(timeEntrega)"></v-time-picker>
            </v-menu>

            <p class="mt-5 mb-0 fs-16" v-if="updateItemStage.enableImage">Seleccione las imagenes que desea vincular con la tarea.</p>

            <v-file-input v-if="updateItemStage.enableImage" v-model="imagesUpload" counter multiple truncate-length="15"></v-file-input>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="red darken-1" text @click="dialog_confirm_updateStage = false">
              Cancelar
            </v-btn>

            <v-btn color="green darken-1" :loading="loadingUpdate" text @click="confirmUpdateStage()">
              Editar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <ModalImages :dialog_images="dialog_images" :stage="updateItemStage" @close="dialog_images = false" />

      <ModalAddImages :item="itemAddImages" :dialog="dialog_add_image" @close="dialog_add_image = false" @uploadImages="addImagesRuta" :loading="loadingAddImage" />

      <ReporteRutas :reportGenerate="reportGenerate" :ruta="rutaSeleccionada" @closeReporte="reportGenerate = false" />
    </v-container>
  </div>
</template>

<script>
import { choferesService } from "@/libs/ws/choferesService";
import { rutasContainerService } from "@/libs/ws/rutasContainerService";
import { vehiculoService } from "@/libs/ws/vehiculoService";
import { clientService } from "@/libs/ws/clientService";
import { imageUtils } from "@/libs/utils/imageUtils";
import { typeTripService } from "@/libs/ws/typeTripService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import ModalAddImages from "./ModalAddImages.vue";
import ReporteRutas from "./reportesRutas.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import ModalImages from "./ModalImages.vue";

import RolMixin from "@/mixins/RolMixin.vue";

export default {
  components: {
    Title,
    Modal,
    ModalImages,
    DialogDelete,
    ModalAddImages,
    ReporteRutas,
  },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      dialog_confirm_updateStage: false,
      dialog_confirm_resetStage: false,
      remitos_drag: [],
      remitos_drag_aux: [],
      expanded: [],
      selected_remitos: [],
      rutaSeleccionada: null,
      color_filter: null,
      dialog_seleccionar: false,
      dialogRemitos: false,
      editedIndexRemito: -1,
      viewdIndexRemito: -1,
      deleteIndexRemito: -1,
      dialog_deleteRemito: false,
      dialogInfo: false,
      pdfRemitoURL: "",
      reportItem: null,
      reportGenerate: false,
      parent: this,
      items_color: rutasContainerService.getStates(),
      service: rutasContainerService,
      page_title: "Contenedores > " + this.$t("Rutas"),
      modal_title: this.$t("Ruta"),
      operadores: [],
      operador_filter: null,
      dateInicio_filter: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      dateFin_filter: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      chofer_filter: null,
      ruta_filter: "",
      cliente_filter: null,
      externalCode_filter: "",
      vehiculo_filter: null,
      numContainer_filter: "",
      onlyPendientes_filter: false,
      drivers: [],
      vehiculos: [],
      clients: [],
      updateItemStage: null,
      dateEntregada: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      timeEntrega: null,
      menu_time: false,
      imagesUpload: [],
      dialog_images: false,
      mainItem: {
        id: 0,
        externalCode: "",
        numTrip: "",
        datePickup: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        time: "00:00",
        time_delivery: "00:00",
        client: "",
        originPort: "",
        warehouse: "",
        driverPickup: "",
        vehiclePickup: {
          id: "",
          patent: "",
          companyTransport: {
            nameTransport: "",
            type: "",
            maxCharge: "",
          },
          type: {
            type: "",
          },
        },
        typeChargePickup: "",
        zonePickup: "",
        typeTripPickup: "",
        dateDelivery: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        destinationPort: "",
        driverDelivery: "",
        vehicleDelivery: {
          id: "",
          patent: "",
          companyTransport: {
            nameTransport: "",
            type: "",
            maxCharge: "",
          },
          type: {
            type: "",
          },
        },
        numContainer: "",
        pesoContainer: "",
        basicCost: "",
        totalCost: "",
        observation1: "",
        observation2: "",
        companyCustody: null,
        quantityModules: 0,
        costModules: "",
        aditionals: [],
        stages: [],
        isActive: false,
      },
      defaultItem: null,
      defaultItemRemito: null,
      headers: [
        { text: "", value: "data-table-expand" },
        { text: "N°" + this.$t("Ruta"), value: "number" },
        {
          text: this.$t("Fecha"),
          align: "left",
          sortable: true,
          value: "date",
          width: "110px",
        },

        {
          text: "Servicio",
          align: "left",
          sortable: true,
          value: "typeServiceCharge.typeServiceCharge",
          width: "125px",
        },

        {
          text: "Cliente",
          align: "left",
          sortable: true,
          value: "client.nameClient",
        },
        {
          text: "Origen",
          align: "left",
          sortable: true,
          value: "originPort.dirOrigen",
        },
        {
          text: "Tipo de vehiculo",
          align: "left",
          sortable: true,
          value: "vehiclePickup.type.type",
        },
        {
          text: "Patente",
          align: "left",
          sortable: true,
          value: "vehiclePickup.patent",
        },
        {
          text: this.$t("Chofer"),
          align: "left",
          sortable: true,
          value: "driverPickup.nameDriver",
        },
        {
          text: "Estado",
          align: "center",
          sortable: true,
          value: "",
          width: "150px",
        },
        {
          text: this.$t("Accion"),
          value: "enable",
          align: "center",
          width: "170px",
        },
      ],
      list: [],
      headersStages: [
        {
          text: this.$t("Orden"),
          align: "center",
          sortable: false,
          value: "order",
          class: "grey lighten-2",
          width: "100px",
        },
        {
          text: "Descripcion",
          align: "left",
          sortable: false,
          value: "description",
          class: "grey lighten-2",
          width: "350px",
        },
        {
          text: "Estado",
          align: "left",
          sortable: true,
          value: "description",
          class: "grey lighten-2",
          width: "200px",
        },
        {
          text: "Fecha de finalizacion",
          align: "left",
          sortable: false,
          value: "description",
          class: "grey lighten-2",
          width: "200px",
        },

        {
          text: this.$t("Accion"),
          value: "enable",
          align: "left",
          sortable: false,
          class: "grey lighten-2",
        },
      ],
      dialog_desasociate: false,
      itemDesasociate: null,
      loadingUpdate: false,
      loadingAddImage: false,
      dialog_add_image: false,
      itemAddImages: {
        ruta: "",
        imagesUpload: [],
        images: [],
      },
      itemAddImagesDefault: null,
    };
  },
  mounted() {
    if (this.$route.params.newroute == "newroute") {
      this.dialog = true;
    }

    this.defaultItem = Object.assign({}, this.mainItem);
    this.itemAddImagesDefault = Object.assign({}, this.itemAddImages);

    this.loadListCustom(this.service);
    this.loadChoferes();
    this.loadVehicles();
    this.loadClients();
    this.loadOperador();
  },
  computed: {
    formBtnRemito() {
      if (this.editedIndexRemito === -1 && this.viewdIndexRemito === -1) {
        return this.$t("Crear");
      } else if (this.viewdIndexRemito === -1) {
        return "Editar";
      }
      return "";
    },
    colorBG() {
      return {};
    },
  },
  methods: {
    loadOperador() {
      typeTripService
        .getAll()
        .then((response) => {
          this.operadores = response.types;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    generarHojaDeRuta(item) {
      this.rutaSeleccionada = item;
      this.reportGenerate = true;
    },
    openAddImages(item) {
      this.itemAddImages.ruta = item.id;
      this.itemAddImages.images = item.images;
      this.dialog_add_image = true;
    },
    async addImagesRuta(param) {
      this.loadingAddImage = true;

      param.ruta = this.itemAddImages.ruta;

      var images = [];
      if (param.imagesUpload.length > 0) {
        for (const element of param.imagesUpload) {
          var obj = { image: null };
          obj.image = await imageUtils.getBase64(element);
          images.push(obj);
        }
      }

      param.images = images;

      rutasContainerService
        .addImages(param)
        .then((response) => {
          this.$toast.success("Las imagenes del viaje se guardaron correctamente.", "OK");
          this.dialog_add_image = false;
          this.itemAddImages = Object.assign({}, this.itemAddImagesDefault);
          this.loadingAddImage = false;
          this.loadPageCustom(this.service);
        })
        .catch((e) => {
          this.loadingAddImage = false;
        });
    },
    loadClients() {
      clientService
        .getAll()
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
    },
    loadVehicles() {
      vehiculoService
        .getAll()
        .then((response) => {
          this.vehiculos = response.vehicles;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadChoferes() {
      choferesService
        .getAll()
        .then((response) => {
          this.drivers = response.drivers;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },
    loadListCustom(service, startIndex = 0) {
      this.loading = true;

      //Controls
      var param = {
        dateInicio_filter: this.formatDate(this.dateInicio_filter),
        dateFin_filter: this.formatDate(this.dateFin_filter),
        chofer_filter: this.chofer_filter,
        ruta_filter: this.ruta_filter,
        numContainer_filter: this.numContainer_filter,
        vehiculo_filter: this.vehiculo_filter,
        externalCode_filter: this.externalCode_filter,
        cliente_filter: this.cliente_filter,
        color_filter: this.color_filter,
        operador_filter: this.operador_filter,
      };
      //
      service
        .filter(param, startIndex, this.$store.state.config.items_per_page)
        .then((response) => {
          if (startIndex == 0) {
            this.current_page = 1;
          }
          console.log(response.list);
          this.list = response.list;
          this.cant_pages = Math.ceil(response.quantity / this.$store.state.config.items_per_page);
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    loadPageCustom(service) {
      this.loadListCustom(service, (this.current_page - 1) * this.$store.state.config.items_per_page);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    updateStageState(item) {
      this.imagesUpload = [];
      this.updateItemStage = item;

      this.timeEntrega = new Date().getHours() + ":" + new Date().getMinutes();
      this.dialog_confirm_updateStage = true;
    },
    resetStage(item) {
      this.updateItemStage = item;
      this.dialog_confirm_resetStage = true;
    },
    viewImage(item) {
      this.updateItemStage = item;
      this.dialog_images = true;
    },
    toIsoString(date) {
      var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? "+" : "-",
        pad = function(num) {
          return (num < 10 ? "0" : "") + num;
        };

      return (
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(date.getHours()) +
        ":" +
        pad(date.getMinutes()) +
        ":" +
        pad(date.getSeconds()) +
        dif +
        pad(Math.floor(Math.abs(tzo) / 60)) +
        ":" +
        pad(Math.abs(tzo) % 60)
      );
    },
    toDateString(dateIsoString) {
      if (dateIsoString == null) {
        return dateIsoString;
      }
      //"2022-04-18T16:19:40-03:00"
      var date = dateIsoString.split("T");
      var hora = date[1].split("-");
      date = date[0].split("-");
      date = date[2] + "-" + date[1] + "-" + date[0] + " " + hora[0];
      return date;
    },
    async confirmUpdateStage() {
      this.loadingUpdate = true;
      var images = [];
      if (this.imagesUpload.length > 0) {
        for (const element of this.imagesUpload) {
          var obj = { image: null };
          obj.image = await imageUtils.getBase64(element);
          images.push(obj);
        }
      }

      var date = new Date(this.dateEntregada + " " + this.timeEntrega + ":00");

      var param = {
        id: this.updateItemStage.id,
        date: this.toIsoString(date), //"2022-04-10T14:24:00-03:00",
        images: images,
      };
      //
      this.service
        .updateStage(param)
        .then((response) => {
          this.loadingUpdate = false;
          this.dialog_confirm_updateStage = false;
          this.dateEntregada = new Date(
            new Date().toLocaleDateString("en-US", {
              timeZone: "America/Buenos_Aires",
            })
          )
            .toISOString()
            .slice(0, 10);
          this.loadPageCustom(this.service);
        })
        .catch((e) => {
          this.loadingUpdate = false;
          console.log(e);
          this.dateEntregada = new Date(
            new Date().toLocaleDateString("en-US", {
              timeZone: "America/Buenos_Aires",
            })
          )
            .toISOString()
            .slice(0, 10);
          this.dialog_confirm_updateStage = false;
        });
    },
    confirmResetStage() {
      this.loadingUpdate = true;

      this.service
        .resetStage(this.updateItemStage.id)
        .then((response) => {
          this.loadingUpdate = false;
          this.dialog_confirm_resetStage = false;
          this.loadPageCustom(this.service);
        })
        .catch((e) => {
          this.dialog_confirm_resetStage = false;
          this.loadingUpdate = false;
          console.log(e);
        });
    },
    getDelivery(items) {
      var contador = 0;
      if (items.length == 0) {
        return 0;
      }
      items.forEach(function(elemento) {
        if (elemento.delivery != null) {
          contador++;
        }
      });
      return contador;
    },
    setColor(items) {
      var total = items.length;
      var cerrados = this.getDelivery(items);
      if (cerrados == 0) {
        return "#ff5252";
      } else if (cerrados > 0 && cerrados < total) {
        return "#7390fc";
      } else if (total == cerrados) {
        return "#5fba7d";
      }
    },
  },
};
</script>

import { genericService } from "./genericService";

const section = "Cluster";

function list(params) {
  return genericService.get(section + "/ListPaginate/" + params + "/0/10");
}

function getAll(params) {
  return genericService.get(section + "/ListCluster");
}

function create(item) {
  const param = {
    Name: item.name,
    Localities: item.localities,
  };
  return genericService.create(section, param);
}

function remove(id) {
  return genericService.remove(section, id);
}

function update(item) {
  const param = {
    Name: item.name,
    Localities: item.localities,
  };
  return genericService.update(section, param);
}

export const clusterService = {
  list,
  getAll,
  create,
  update,
  remove,
};

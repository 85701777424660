import { genericService } from "./genericService";

const section = "Adicional";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filter(section, "adicionales", name, from, limit, active);
}

function getAll() {
  return genericService.filter(section, "adicionales", " ", 0, 10000, 1);
}

function remove(id) {
  return genericService.remove(section, id);
}

function create(item) {
  const param = {
    Nombre: item.nombre,
    UseRemito: item.useremito,
  };
  return genericService.create(section, param);
}

function update(item) {
  const param = {
    id: item.id,
    Nombre: item.nombre,
    UseRemito: item.useremito,
  };
  return genericService.update(section, param);
}

export const adicionalesService = {
  getAll,
  filter,
  remove,
  create,
  update,
};

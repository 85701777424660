<template>
  <div>
    <Title :pages="[{ icon: 'clipboard-list', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <Modal
        v-if="create_access"
        :button_name="$t('Crear') + ' ' + modal_title"
        :modal_title="modal_title"
        :formBtn="formBtn"
        :dialog="dialog"
        :parent="parent"
        :service="service"
        @updateDialog="updateDialog"
        @loadList="loadList(service, name_filter, (current_page - 1) * $store.state.config.items_per_page)"
        :item="mainItem"
        @updateItem="
          (newItem) => {
            mainItem = newItem;
          }
        "
      />

      <!--Filter-->
      <div class="row mb-3" v-if="list_access">
        <div class="col-md-12">
          <div class="filter">
            <div class="d-flex align-items-center">
              <h4 class="title">{{ $t("Filtros") }}</h4>
              <font-awesome-icon icon="filter" />
            </div>
            <hr />
            <div class="row">
              <div class="col-md-4">
                <v-text-field data-vv-as="nombre" :label="$t('Nombre') + '*'" name="zone" v-model="name_filter"></v-text-field>
              </div>
              <div class="col-md-4">
                <v-checkbox :label="$t('Activo')" v-model="active_filter"></v-checkbox>
              </div>

              <div class="col-md-4 d-flex align-items-center justify-content-end pr-10">
                <v-btn color="primary" dark @click="loadList(service)">{{ $t("Buscar") }}</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Filter -->

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" :label="$t('Buscar')" single-line hide-details></v-text-field>
        </v-card-title>
        <div class="mb-4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-show="selected.length != 0" v-on="on" color="green" class="ml-4" @click="incrementPrice()">
                add
              </v-icon>
            </template>
            <span>Aumentar precios</span>
          </v-tooltip>
        </div>
        <v-data-table :headers="headers" :items="list" :search="search" :loading="loading" :loading-text="$t('cargando_espere')" v-model="selected" show-select :single-select="false">
          <template v-slot:item="props">
            <tr>
              <td>
                <v-checkbox :input-value="props.isSelected" style="margin:0px;padding:0px ;" hide-details @change="props.select($event)" />
              </td>
              <td>
                {{ (pagination.page - 1) * pagination.itemsPerPage + props.index + 1 }}
              </td>
              <td>{{ props.item.vehicleType.type }}</td>
              <td>{{ props.item.chargeType.typeCharge }}</td>
              <td>{{ props.item.zone.zone }}</td>
              <td class="pb-2">
                <v-chip class="mr-1 mt-2" v-for="obj in props.item.transportes" :key="obj.id" small>
                  <span class="truncate" :title="obj.nameTransport">
                    {{ obj.nameTransport }}
                  </span>
                </v-chip>
              </td>
              <td>{{ formatUtils.formatPrice(props.item.basicCost) }}</td>
              <td class="justify-content-center px-0">
                <v-tooltip bottom v-if="update_access">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)">
                      edit
                    </v-icon>
                  </template>
                  <span>{{ $t("Editar") }}</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-1" @click="viewItem(props.item)">
                      remove_red_eye
                    </v-icon>
                  </template>
                  <span>{{ $t("Ver") }}</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.isActive">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" @click="deleteItem(props.item)">
                      delete
                    </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ $t("sin_resultados") + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
      <!-- 
      <div v-if="cant_pages > 1" class="text-center">
        <v-pagination
          v-if="list_access"
          class="mt-5 mb-5"
          v-model="current_page"
          :length="cant_pages"
          total-visible="10"
          circle
          @next="loadPage(service)"
          @previous="loadPage(service)"
          @input="loadPage(service)"
        ></v-pagination>
      </div> -->

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" />
      <v-dialog max-width="600" v-model="dialog_increment">
        <template v-slot:default="dialog">
          <v-card>
            <v-card-title> Incrementar precios </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-md-12">
                  <h6 style="color:black" class="mb-0" v-if="selected.length == 1">Seleccionaste {{ selected.length }} tarifa</h6>
                  <h6 v-else style="color:black" class="mb-0">Seleccionaste {{ selected.length }} tarifas</h6>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <v-text-field label="Porcentaje a aumentar" v-model="porcentage" suffix="%"> </v-text-field>
                </div>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false" color="red">Cerrar</v-btn>
              <v-btn text @click="incrementPriceConfirm()" color="green">Confirmar</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import { purchaseRateService } from "@/libs/ws/purchaseRateService";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import Modal from "./Modal.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import { formatUtils } from "@/libs/utils/formatUtils";
export default {
  components: { Title, Modal, DialogDelete },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      parent: this,
      formatUtils: formatUtils,
      service: purchaseRateService,
      page_title: this.$t("Tarifas_de_compra"),
      modal_title: this.$t("Tarifas_de_compra"),
      mainItem: {
        id: "",
        zone: null,
        vehicleType: null,
        chargeType: null,
        basicCost: 0,
        transportes: null,
        isActive: true,
        adicionales: [],
      },
      defaultItem: null,
      headers: [
        { text: this.$t("Numero"), value: "number" },
        {
          text: "Tipo de " + this.$t("Vehiculo"),
          align: "left",
          sortable: true,
          value: "vehicleType.name",
        },
        {
          text: "Servicio",
          align: "left",
          sortable: true,
          value: "chargeType.name",
        },
        {
          text: "Zona",
          align: "left",
          sortable: true,
          value: "zone.name",
        },
        {
          text: "Transportes",
          align: "left",
          sortable: true,
          value: "transportes",
        },
        {
          text: "Costo basico",
          align: "left",
          sortable: true,
          value: "basicCost",
        },
        { text: this.$t("Accion"), value: "enable", align: "center" },
      ],
      list: [],
      selected: [],
      dialog_increment: false,
      porcentage: 0,
    };
  },
  mounted() {
    this.defaultItem = Object.assign({}, this.mainItem);
    this.loadList(this.service);
  },
  methods: {
    deleteItemConfirm() {
      this.delete(this.service);
    },
    incrementPrice() {
      this.dialog_increment = true;
    },
    delete(service) {
      service
        .remove(this.deleteId)
        .then((response) => {
          this.$toast.success("La tarifa se borro correctamente", "OK");
          this.loadList(this.service);
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {
          this.dialog_delete = false;
        });
    },
    incrementPriceConfirm() {
      var arrayIDs = [];
      this.selected.forEach((element) => {
        arrayIDs.push(element.id);
      });
      var param = { ids: arrayIDs, porcentage: this.porcentage };

      console.log(param);
      purchaseRateService
        .incrementPrice(param)
        .then((response) => {
          this.loadList(this.service);
          this.dialog_increment = false;
          this.$toast.success("Se incrementaron los precios", "OK");
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Ocurrió un error", "Error");
        });
    },
  },
};
</script>

import { parse } from "@fortawesome/fontawesome-svg-core";
import { genericService } from "./genericService";

const section = "PurchaseRateCompany";

function filter(name = " ", from = "0", limit = "10", active = "1") {
  return genericService.filterV2(section + "/ListPurchaseRate", "purchaseRates", name, from, limit, active);
}

function remove(id) {
  return genericService.removeV2(section + "/DeletePurchaseRate", id);
}

function create(item) {
  var adicionals = [];
  item.adicionales.forEach((element) => {
    adicionals.push({
      idAditional: element.adicional.id,
      Cost: parseFloat(element.cost),
    });
  });

  var transportes = [];
  item.transportes.forEach((element) => {
    transportes.push(element.id);
  });
  const param = {
    IdVehicleType: item.vehicleType.id,
    IdChargeType: item.chargeType.id,
    IdZone: item.zone.id,
    BasicCost: parseFloat(item.basicCost),
    Aditionales: adicionals,
    Transportes: transportes,
    isActive: item.isActive,
  };

  return genericService.createV2(section + "/CreatePurchaseRate", param);
}

function update(item) {
  var adicionals = [];
  item.adicionales.forEach((element) => {
    adicionals.push({
      IdAditional: element.adicional.id,
      Cost: parseFloat(element.cost),
    });
  });
  var transportes = [];
  item.transportes.forEach((element) => {
    transportes.push(element.id);
  });
  const param = {
    id: item.id,
    IdVehicleType: item.vehicleType.id,
    IdChargeType: item.chargeType.id,
    IdZone: item.zone.id,
    BasicCost: parseFloat(item.basicCost),
    Aditionales: adicionals,
    Transportes: transportes,
    isActive: item.isActive,
  };

  return genericService.updateV2(section + "/UpdatePurchaseRate", param);
}

function incrementPrice(item) {
  const param = {
    Percentage: parseFloat(item.porcentage),
    PurchaseRates: item.ids,
  };

  return genericService.updateV2(section + "/UpdatePurchaseRateMasive", param);
}

function getBasicCost(IdVehicle, IdTypeCharge, IdZone) {
  return genericService.get("PurchaseRate/FindPurchaseRate/" + IdVehicle + "/" + IdTypeCharge + "/" + IdZone);
}

function FindPurchaseRateNew(IdVehicle, IdTypeCharge, IdZone) {
  return genericService.get("PurchaseRate/FindPurchaseRateNew/" + IdVehicle + "/" + IdTypeCharge + "/" + IdZone);
}

function FindPurchaseRateNewV2(IdVehicle, IdTypeCharge, IdZone, IdTransporte) {
  return genericService.get("PurchaseRateCompany/FindPurchaseRate/" + IdVehicle + "/" + IdTypeCharge + "/" + IdZone + "/" + IdTransporte);
}

export const purchaseRateService = {
  getBasicCost,
  filter,
  remove,
  create,
  update,
  incrementPrice,
  FindPurchaseRateNew,
  FindPurchaseRateNewV2,
};

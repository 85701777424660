import { akeronService } from "./akeronService";

function filter(section, list_field_name, name = " ", from = "0", limit = "10", active = "1") {
  return akeronService.make_get_request(section + "/List" + section + "/" + name + "/" + from + "/" + limit + "/" + active, {}).then((response) => {
    let result = {
      quantity: response.quantity,
      list: response[list_field_name],
    };
    return result;
  });
}

function filterV2(section, list_field_name, name = " ", from = "0", limit = "10", active = "1") {
  return akeronService.make_get_request(section + "/" + name + "/" + from + "/" + limit + "/" + active, {}).then((response) => {
    let result = {
      quantity: response.quantity,
      list: response[list_field_name],
    };
    return result;
  });
}

function get(path, params = {}) {
  if (params == {}) {
    return akeronService.make_get_request(path, {}).then((response) => {
      return response;
    });
  } else {
    return akeronService.make_get_param_request(path, params).then((response) => {
      return response;
    });
  }
}

function remove(section, id) {
  const param = {
    id: id,
  };
  return akeronService.make_delete_request(section + "/Delete" + section + "", param);
}

function create(section, param) {
  return akeronService.make_post_request(section + "/Create" + section + "", param);
}

function createV2(section, param) {
  return akeronService.make_post_request("/" + section + "", param);
}

function update(section, param) {
  return akeronService.make_put_request(section + "/Update" + section, param);
}

function updateV2(section, param) {
  return akeronService.make_put_request("/" + section, param);
}

function removeV2(section, id) {
  const param = {
    id: id,
  };
  return akeronService.make_delete_request("/" + section + "", param);
}

export const genericService = {
  removeV2,
  updateV2,
  createV2,
  filterV2,
  get,
  filter,
  remove,
  create,
  update,
};

<template>
  <GmapMap
    :center="getCenter()"
    :zoom="11"
    :options="{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: true,
      fullscreenControl: true,
      disableDefaultUi: true,
    }"
    map-type-id="terrain"
    style="width: 100%; height: 500px"
  >
    <div v-for="remito of remitos" :key="remito.id">
      >
      <GmapMarker
        v-if="remito.subClient.latitudAddress != null && remito.subClient.longitudAddress != null"
        :position="getPosition(remito.subClient.latitudAddress, remito.subClient.longitudAddress)"
        :icon="getIcon(remito)"
      >
      </GmapMarker>
      <!-- 
      <GmapInfoWindow>
        <div style="padding:5px">
          <span class="fs-15">{{ 12 }}</span>
        </div>
      </GmapInfoWindow> -->
    </div>
    <GmapMarker v-if="driver.latitud != null && driver.longitud != null" :position="getPosition(driver.latitud, driver.longitud)" :icon="require('@/assets/images/marker-truck.png')"> </GmapMarker>
  </GmapMap>
</template>

<script>
import { gmapApi } from "vue2-google-maps";

export default {
  name: "GeoMultipleMarkers",

  components: {},
  props: {
    remitos: Array,
    driver: Object,
    ruta: Object,
  },
  data() {
    return {
      lngMin: null,
      latMin: null,
      lngMax: null,
      latMax: null,
    };
  },
  computed: {
    google: gmapApi,
    remitosObject() {
      return this.remitos;
    },
  },
  watch: {
    remitosObject() {
      this.remitos.forEach((element) => {
        this.$set(element.shipping, "open", false);
      });
    },
  },
  mounted() {},
  methods: {
    getCenter() {
      var lat = (this.latMax + this.latMin) / 2;
      var lng = (this.lngMax + this.lngMin) / 2;
      return { lat: lat, lng: lng };
    },
    getIcon(assigned) {
      switch (assigned.estate.id) {
        case 1:
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + assigned.orden + "|1b9e8d|000000";
        case 2:
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + assigned.orden + "|dd4b39|000000";
        case 3:
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + assigned.orden + "|fff159|000000";
        case 4:
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + assigned.orden + "|5fba7d|000000";
        case 5:
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + assigned.orden + "|883bbb|000000";
        case 6:
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" + assigned.orden + "|7390fc|000000";
      }

      /*  // no entregado
      if (assigned.closed == true && assigned.delivered == null) {
        if (assigned.orden != 0) {
          return (
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" +
            assigned.orden +
            "|fff159|000000"
          );
        } else {
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=fr|fff159|000000";
        }
      }
      // en camino
      if (assigned.closed != true && ruta.initialDate != null) {
        if (assigned.orden != 0) {
          return (
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" +
            assigned.orden +
            "|7390fc|000000"
          );
        } else {
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=fr|7390fc|000000";
        }
      }
      // Entregado
      if (assigned.closed == true && ruta.initialDate != null) {
        if (assigned.orden != 0) {
          return (
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" +
            assigned.orden +
            "|5fba7d|000000"
          );
        } else {
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=fr|5fba7d|000000";
        }
      }

      // En espera
      if (assigned.closed != true && ruta.initialDate == null) {
        if (assigned.orden != 0) {
          return (
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=" +
            assigned.orden +
            "|ff5252|000000"
          );
        } else {
          return "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=fr|ff5252|000000";
        }
      } */
    },
    getPosition(lat, lng) {
      if (this.latMax == null || this.latMax < parseFloat(lat)) {
        this.latMax = parseFloat(lat);
      }

      if (this.latMin == null || this.latMin > parseFloat(lat)) {
        this.latMin = parseFloat(lat);
      }

      if (this.lngMax == null || this.lngMax < parseFloat(lng)) {
        this.lngMax = parseFloat(lng);
      }

      if (this.lngMin == null || this.lngMin > parseFloat(lng)) {
        this.lngMin = parseFloat(lng);
      }

      this.center = this.getCenter();

      return { lat: parseFloat(lat), lng: parseFloat(lng) };
    },
  },
};
</script>

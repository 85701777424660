<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="1050px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <v-stepper v-model="step">
              <v-stepper-header style="background:#f7f7f7">
                <v-stepper-step :complete="step > 1" step="1">
                  Paso 1
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2">
                  Paso 2
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12" elevation="0">
                    <!-- START FORM DIALOG -->
                    <div class="row justify-content-center">
                      <div class="col-md-5">
                        <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
                        <v-combobox
                          v-model="item.vehicleType"
                          data-vv-as="Tipo de vehiculo"
                          name="idVehicleType"
                          item-text="type"
                          item-value="id"
                          :items="tipoDeVehiculos"
                          :label="'Tipo de ' + $t('Vehiculo')"
                          autocomplete="off"
                          v-validate="'required'"
                          :disabled="isViewModal()"
                        ></v-combobox>
                        <span class="red--text">{{ errors.first("idVehicleType") }}</span>
                      </div>
                      <div class="col-md-5">
                        <v-combobox
                          v-model="item.chargeType"
                          data-vv-as="Servicio"
                          name="idChargeType"
                          item-text="typeCharge"
                          item-value="id"
                          :items="tipoDeCagas"
                          label="Servicio"
                          autocomplete="off"
                          v-validate="'required'"
                          :disabled="isViewModal()"
                        ></v-combobox>
                        <span class="red--text">{{ errors.first("idChargeType") }}</span>
                      </div>
                      <div class="col-md-5">
                        <v-combobox
                          v-model="item.zone"
                          data-vv-as="zona"
                          name="idZone"
                          item-text="zone"
                          item-value="id"
                          :items="zonas"
                          label="Zona"
                          autocomplete="off"
                          v-validate="'required'"
                          :disabled="isViewModal()"
                        ></v-combobox>
                        <span class="red--text">{{ errors.first("idZone") }}</span>
                      </div>
                      <div class="col-md-5">
                        <v-text-field
                          data-vv-as="Costo basico"
                          :label="$t('Costo') + 'básico*'"
                          name="costo_basico"
                          v-model="item.basicCost"
                          v-validate="'required|decimal'"
                          :disabled="isViewModal()"
                        ></v-text-field>
                        <span class="red--text">{{ errors.first("costo_basico") }}</span>
                      </div>

                      <div class="col-md-10">
                        <v-combobox
                          v-model="item.transportes"
                          chips
                          small-chips
                          data-vv-as="transportes"
                          name="transportes"
                          item-text="nameTransport"
                          item-value="id"
                          multiple
                          :items="transportes"
                          label="Transportes"
                          autocomplete="off"
                          v-validate="'required'"
                          :disabled="isViewModal()"
                        ></v-combobox>
                        <span class="red--text">{{ errors.first("transporte") }}</span>
                      </div>
                      <div class="col-md-10">
                        <v-checkbox v-if="!isActive" label="Activo" v-model="item.isActive" :disabled="isViewModal()"></v-checkbox>
                      </div>
                    </div>
                  </v-card>

                  <v-btn color="primary" @click="nextStep">
                    Siguiente
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card class="mb-12" elevation="0" style="min-height:440px">
                    <v-btn small class="mt-3 mb-3" color="primary" @click="addAdicionalModal">
                      Agregar Adicional
                    </v-btn>
                    <v-data-table :headers="headers" :items="item.adicionales">
                      <template v-slot:item="props">
                        <tr>
                          <td>
                            {{ (pagination.page - 1) * pagination.itemsPerPage + props.index + 1 }}
                          </td>
                          <td>{{ props.item.adicional.nombre }}</td>
                          <td>{{ formatUtils.formatPrice(props.item.cost) }}</td>
                          <td class="justify-content-center px-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)">
                                  edit
                                </v-icon>
                              </template>
                              <span>{{ $t("Editar") }}</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on" small color="red" @click="deleteItem(props.item)">
                                  delete
                                </v-icon>
                              </template>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card>

                  <v-btn text @click="step = 1">
                    Atras
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit" v-if="step == 2 && !isViewModal()">{{ formBtn }}</v-btn>
          </v-card-actions>

          <ModalAdicional :itemAdicional="itemAdicional" :dialog="dialog_adicional" @close="dialog_adicional = false" @action="addAdicional" />
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { zoneService } from "@/libs/ws/zoneService";
import { typeVehicleService } from "@/libs/ws/typeVehicleService";
import { typeService } from "@/libs/ws/typeService";
import { transportService } from "@/libs/ws/transportService";
import { formatUtils } from "@/libs/utils/formatUtils";

import ModalAdicional from "./ModalAdicional.vue";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  components: {
    ModalAdicional,
  },
  data() {
    return {
      formatUtils: formatUtils,
      dialog_adicional: false,
      tipoDeVehiculos: [],
      tipoDeCagas: [],
      zonas: [],
      transportes: [],
      headers: [
        { text: "Nro", value: "id", sortable: false },
        { text: "Adicional", value: "adicional", sortable: true },
        { text: "Importe", value: "costo", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      step: 1,
      pagination: {
        page: 1,
        itemsPerPage: 5,
      },
      itemAdicional: null,
      isActive: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.step = 1;
      this.errors.clear();
      this.isActive = this.item.isActive;

      if (this.item.id == 0 && this.dialog) {
        this.item.adicionales = [];
      }
    },
  },
  mounted() {
    this.loadZonas();
    this.loadTipoVehiculo();
    this.loadTipoCarga();
    this.loadTransporte();
  },
  methods: {
    nextStep() {
      this.errors.clear();
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.step = 2;
        }
      });
    },
    deleteItem(item) {
      this.item.adicionales.splice(this.item.adicionales.indexOf(item), 1);
    },
    editItem(item) {
      this.itemAdicional = item;
      this.dialog_adicional = true;
    },
    addAdicionalModal() {
      this.itemAdicional = null;
      this.dialog_adicional = true;
    },
    addAdicional(item) {
      if (this.itemAdicional != null) {
        this.item.adicionales[this.item.adicionales.indexOf(this.itemAdicional)] = item;
      } else {
        //busco si existe un adicional con el mismo id
        let index = this.item.adicionales.findIndex((x) => x.adicional.id == item.adicional.id);
        if (index >= 0) {
          this.$toast.error("Ya existe el adicional en el listado, no puede agregarlo nuevamente", "Error");
          return;
        } else {
          this.item.adicionales.push(item);
        }
      }

      this.dialog_adicional = false;
    },
    loadTransporte() {
      transportService
        .getAll()
        .then((response) => {
          this.transportes = response.transports;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadZonas() {
      zoneService
        .filter(" ", 0, zoneService.ZONES_PAGINATION, 1)
        .then((response) => {
          this.zonas = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTipoVehiculo() {
      typeVehicleService
        .filter(" ", 0, 100, 1)
        .then((response) => {
          this.tipoDeVehiculos = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    loadTipoCarga() {
      typeService
        .filter(" ", 0, 100, 1)
        .then((response) => {
          this.tipoDeCagas = response.list;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_actualizo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                if (response.id > 0) {
                  this.$toast.success(this.$t("se_creo_ok"), "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else this.$toast.error(response.title, "Error");
              })
              .catch((response) => {
                this.$toast.error(response.title, "Error");
              });
          }
        }
      });
    },
  },
};
</script>

<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" v-on:input="$emit('updateDialog', false)" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <v-text-field label="id" v-model="item.id" v-show="false" required></v-text-field>
            <div class="row">
              <div class="col-md-12">
                <v-text-field data-vv-as="nombre" :label="$t('Nombre')" name="nombre" v-model="item.nombre" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("nombre") }}</span>

                <v-checkbox :label="$t('Aplica sobre remito')" v-model="item.useremito"></v-checkbox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" :loading="loading" text type="submit">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { vehiculoService } from "@/libs/ws/vehiculoService";
export default {
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      vehiculos: [],
      loading: false,
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadVehiculos();
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    loadVehiculos() {
      vehiculoService
        .getAll()
        .then((response) => {
          this.vehiculos = response.vehicles;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    createItem() {
      if (this.loading) return;

      this.loading = true;

      if (this.item.id > 0) {
        this.service
          .update(this.item)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_actualizo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.service
          .create(this.item)
          .then((response) => {
            if (response.id > 0) {
              this.$toast.success(this.$t("se_creo_ok"), "OK");
              this.$emit("updateDialog", false);
              this.$emit("loadList");
            } else this.$toast.error(response.title, "Error");
          })
          .catch((response) => {
            this.$toast.error(response.title, "Error");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

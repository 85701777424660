<template>
  <div v-if="ruta != null">
    <vue-html2pdf
      :manual-pagination="true"
      pdf-content-width="100%"
      ref="html2Pdf"
      :preview-modal="false"
      :pdf-quality="2"
      :enable-download="true"
      :htmlToPdfOptions="htmlToPdfOptions"
      @hasDownloaded="$emit('closeReporte')"
    >
      <section slot="pdf-content">
        <section class="pdf-item">
          <div class="row">
            <div class="col">
              <h5 style="margin-left:15px;margin-top:10px;font-size: 11px">
                Fecha:&nbsp;{{ getFecha(ruta.datePickup) }}&nbsp;| {{ ruta.datePickup.substr(11, 8) }}&nbsp; &nbsp;-&nbsp;Página N°&nbsp;1
              </h5>
            </div>
          </div>
          <hr class="mt-1 mb-3" />
          <div class="row">
            <div style="width:12%">
              <h3 style="margin-left:30px;font-size: 11px" class="pt-1 mb-0">
                HOJA DE RUTA N°
              </h3>
              <h3 style="margin-left:30px;font-size: 30px" class="mb-2">
                {{ ruta.numTrip }}
              </h3>
            </div>
            <table style="width:88%;font-size: 11px">
              <tr>
                <th style="padding-left:30px">Chofer:&nbsp;{{ ruta.driverPickup.nameDriver }}</th>
                <th>Cuit:&nbsp;{{ ruta.driverPickup.cuit }}</th>
                <th>Tel:&nbsp;{{ ruta.driverPickup.driverTelephone }}</th>
                <th>Vehiculo:&nbsp;{{ ruta.vehiclePickup.patent }}</th>
              </tr>
              <tr>
                <th style="padding-left:30px">Tipo de unidad:&nbsp;{{ ruta.vehiclePickup.type.type }}</th>
                <th>Servicio:&nbsp;{{ ruta.typeChargePickup.typeCharge }}</th>
                <th>Origen:&nbsp;{{ ruta.originPort.dirOrigen }}</th>
                <th>Transporte:&nbsp;{{ ruta.vehiclePickup.companyTransport.nameTransport }}</th>
              </tr>
              <tr>
                <th style="padding-left:30px">Zona:&nbsp;{{ ruta.zonePickup.zone }}</th>
                <th>
                  Custodia:&nbsp;
                  {{ ruta.companyCustody != null ? ruta.companyCustody.nameCompany : "" }}
                </th>
                <th style="max-width: 200px">Dir.:&nbsp;{{ ruta.originPort.originAddress }}</th>
                <th>Desconsolidado:&nbsp;{{ ruta.warehouse.nameWarehouse }}</th>
              </tr>
            </table>
          </div>

          <hr class="mt-3 mb-2" />

          <table class="headerReportes" style="width:100%;font-size: 10px">
            <tr>
              <th class="text-center" style="width:3%"></th>
              <th style="width:22%;font-size: 12px">CLIENTE</th>
              <th style="width:20%;font-size: 12px">CONTENEDOR</th>
              <th style="width:25%;font-size: 12px">DEVOLUCION</th>
              <th style="width:25%;font-size: 12px">DIRECCION</th>
            </tr>
          </table>

          <table class="tableReportesContainer" style="width:100%;font-size: 10px;margin-top:5px">
            <tr>
              <th class="text-center" style="width:3%"></th>
              <th style="width:22%;font-size: 11px">{{ ruta.client.nameClient }}</th>
              <th style="width:20%;font-size: 11px">{{ ruta.numContainer }}</th>
              <th style="width:25%;font-size: 11px">{{ ruta.destinationPort.dirOrigen }}</th>
              <th style="width:25%;font-size: 11px">{{ ruta.destinationPort.originAddress }}</th>
            </tr>
          </table>
        </section>

        <section class="pdf-item">
          <div class="footer" style="padding-left:15px ; padding-right: 15px;">
            <div class="row pl-2 pr-2">
              <div class="col-md-12 footer-text mt-4 mb-2">
                <p style="font-size: 8px">
                  SOLO DEBEN CONFORMARSE LOS REMITOS (PUEDEN TENER MAS DE UNA PAGINA). LAS FACTURAS QUEDAN EN EL CLIENTE. EN LOS REMITOS DESPACHADOS POR EXPRESOS DEBEN FIGURAR LA HORA DE ENTREGA Y
                  CANTIDAD DE BULTOS ENTREGADOS. DICHA CANTIDAD DEBE COINCIDIR CON LO DECLARADO POR {{ companyName }} LOS CONFORMES DEBEN SER RENDIDOS A {{ companyName }} SI O SI A MAS TARDAR AL OTRO
                  DIA "URGENTE" AVISAR POR DEMORAS DE MÁS DE 20 MIN. ANTE CUALQUIER PROBLEMA AVISAR URGENTE A TRAFICO.
                </p>
              </div>
            </div>

            <div class="row pl-2 pr-2">
              <div class="col-md-2  pt-0 pb-0">
                <p style="font-size: 11px;" class="mb-1">
                  Vigilancia&nbsp;
                </p>
                <table style="font-size:11px">
                  <tr>
                    <td><p class="mb-1 mr-2">Hora Arribo:</p></td>
                    <td><p class="mb-1">_____________</p></td>
                  </tr>
                  <tr>
                    <td><p class="mb-1 mr-2">Hora Ingreso:</p></td>
                    <td><p class="mb-1">_____________</p></td>
                  </tr>
                  <tr>
                    <td><p class="mb-1 mr-2">Hora Egreso:</p></td>
                    <td><p class="mb-1">_____________</p></td>
                  </tr>
                  <tr>
                    <td><p class="mb-1 mr-2">Intervino:</p></td>
                    <td><p class="mb-1">_____________</p></td>
                  </tr>
                </table>
              </div>
              <div class="col-md-2  pt-0 pb-0">
                <p style="font-size: 11px;" class="mb-0">Observaciones:</p>
                <p style="font-size: 11px" class="mb-0">
                  {{ ruta.observation1 }}
                </p>
                <p style="font-size: 11px" class="mb-0">
                  {{ ruta.observation2 }}
                </p>
              </div>
              <div class="col-md-2  pt-0 pb-0" style="display:flex;justify-content:flex-end;flex-direction: column" v-for="(adicionalArray, index) in adicionales" :key="index">
                <table style="font-size:11px">
                  <tr v-for="(aditional, index) in adicionalArray" :key="index">
                    <td>
                      <p class="mb-1">{{ $t(aditional.tipo) }}:</p>
                    </td>
                    <td>
                      <p class="mb-1">{{ aditional.quantity }}</p>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="col-md-2 pt-0 pb-0" style="display:flex;align-items:flex-end;justify-content:center">
                <p style="font-size: 11px" class="mb-0">
                  Firma&nbsp;y&nbsp;aclaracion&nbsp;del&nbsp;chofer
                </p>
              </div>
              <div class="col-md-3 pt-0 pb-0" style="display:flex;align-items:flex-end;justify-content:center">
                <p style="font-size: 11px" class="mb-0">
                  Firma&nbsp;y&nbsp;aclaracion del&nbsp;responsable logístico
                </p>
              </div>
              <div class="col-md-3 pt-0 pb-0" style="display:flex;align-items:flex-end;justify-content:center">
                <p style="font-size: 11px" class="mb-0">
                  Firma&nbsp;y&nbsp;aclaracion&nbsp;del&nbsp; responsable depósito
                </p>
              </div>
            </div>
          </div>
        </section>
        <div class="html2pdf__page-break" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import { cryptoUtils } from "@/libs/utils/cryptoUtils";
import Constant from "@/constants/";
export default {
  components: { VueHtml2pdf },
  props: {
    dialog: Boolean,
    ruta: Object,
    reportGenerate: Boolean,
    closeReporte: Function,
  },
  watch: {
    reportGenerate() {
      if (this.reportGenerate) {
        this.adicionales = this.sliceIntoChunks(this.ruta.aditionals, 5);
        this.htmlToPdfOptions.filename = "Ruta Nro " + this.ruta.numTrip;
        this.generateReport();
      }
    },
  },
  mounted() {
    this.companyName = cryptoUtils.decrypt(localStorage.getItem("company"), Constant.SECRET_PHRASE);
  },
  data() {
    return {
      companyName: "",
      adicionales: [],
      htmlToPdfOptions: {
        margin: [10, 0, 0, 0],
        filename: "",
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 4,
          scrollY: 0,
          scrollX: 0,
        },
        jsPDF: {
          orientation: "landscape",
          format: "a4",
        },
      },
    };
  },
  methods: {
    generateReport() {
      console.log("voy a generar el reporte");
      setTimeout(() => {
        this.$refs.html2Pdf.generatePdf();
      }, 2000);
    },
    getFecha(date) {
      return new Date(date).toLocaleDateString();
    },
    sliceIntoChunks(arr, chunkSize) {
      const res = [];
      for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
      }
      return res;
    },
  },
};
</script>
